.pricing {
    padding-top: 100px;
    padding-bottom: 60px;
  }
.pricing .tag {
    font-weight: 600;
    font-size: 20px;
    line-height: 122%;
    text-align: center;
    text-transform: capitalize;
    color: #30187B;
    text-align: center;
    width: 100%;
    display: block;
    margin-bottom: 15px;
  }
  .pricing  .intro {
    font-weight: 400;
    font-size: 14px;
    line-height: 128%;
    text-align: center;
    color: #737780;
    text-align: center;
    margin-bottom: 26px;
  }
  .pricing   h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 125%;
    text-align: center;
    color: #05235F;
    margin-bottom: 12px;
  }
  .pricing  ul {
    list-style: none;
    padding: 0;
    padding-top: 30px;
  }

  .pricing  li {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #171B26;
    display: flex;
    justify-content: space-between;
    padding: 0 15px 8px 11px;
  }

  .pricing  li.disabled {
    color: #737780;

  }

  .postHeader {
    font-weight: 500;
    font-size: 16px;
    line-height: 128%;
    text-align: center;
    text-transform: capitalize;
    color: #737780;
    border-bottom: 1px dd #E3E4E5;
  }

  .price-til {
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
  }

  .price-til .btn {
    width: 94%;
    margin: 1px 10px;
    display: block;
    padding: 13px !important;
  }
  

  .compare-pricing thead td{
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #30187B; 
    padding-bottom: 44px;
  }

  .compare-pricing tbody, .compare-pricing td {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #171B26;
    padding: 30px 0; 
  }

  .compare-pricing {
   padding-bottom: 120px;
  }

  .compare-pricing .btn-solid-blue{
    margin: 0;
  }

  .compare-pricing h2{
    background: #FAFAFA;
    border-radius: 100px;
    max-width: 324px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
font-size: 24px;
line-height: 32px;
/* identical to box height, or 133% */

text-align: center;

/* Color Scheme/Secondary */

color: #05235F;
padding: 18px 30px;
margin-bottom: 70px;
  }



  @media screen and (max-width:1200px) {
    .pricing h2 {
      font-size: 33px;
    }
    .price-til li p {
      font-size: 14px;
    }

    .price-til {
      padding: 24px 10px;
      background: #FFFFFF;
      box-shadow: 0px 4px 16px rgba(16, 24, 40, 0.06);
      border-radius: 12px;
      height: 100%;
    }


    .pricing li {
      padding: 0;
    }
    .price-til li span {
      font-size: 12px;
      text-align: right;
      max-width: 172px;
    }
    .price-btn-tablet-responsive{
      margin-top: 65px !important;
    }
    .price-btn-tablet-responsive-2{
      margin-top: 41px !important;
    }
    .compare-pricing img {
      max-width: 19px;
    }

   
  }


  @media screen and (max-width:767px) {
    .compare-pricing tbody, .compare-pricing td {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-transform: capitalize;
      color: #171B26;
    }

    .compare-pricing td.text-center{
      font-size: 14px;
    }

    .compare-pricing img {
      max-width: 14px;
    }
    .compare-pricing .btn-solid-blue {
      margin: 0 7px;
      font-size: 12px !important;
      white-space: nowrap;
      padding: 3px 8px !important;
    }
  }

  .white{
    color: #fff;
  }

  .mb{
    margin-bottom: 30px;
  }

  .tablePrice{
    overflow: scroll;
  }