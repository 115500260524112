.about-team h2{
    font-weight: 600;
    font-size: 40px;
    line-height: 115%;
    text-transform: capitalize;
    color: #212121;
    margin-bottom: 16px;
    padding-left: 10px;
}

.about-team p{
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #212121;
    padding-right: 92px;
    padding-left: 10px;

}

.about-team {
    padding-top: 123px;
    padding-bottom: 111px;
  }

  .about-team .btn.btn-hollow-blue {
    margin-right: 9px;
    padding: 11px 38px !important;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 10px;
  }


  .member-tile {
    margin-top: 47px;
  }

  .member-tile {
    margin-top: 47px;
    padding: 0 13px;
  }


  .member {
    margin-bottom: 26px;
  }

  


  .member-tile h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 124%;
    color: #212121;
    margin-bottom: 6px;
    
  }

  .member-tile p {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #616161;
  }

  


  @media screen and (max-width: 1200px) {

   
    .about-intro-banner.d-sm-none {
      padding-top: 56px;
      padding-left: 8px;
      padding-right: 7px;
    }

    .about-team {
      padding-top: 65px;
      padding-bottom: 56px;
    }
    .about-team h2 {
      font-weight: 600;
      font-size: 27px;
      line-height: 115%;
      text-transform: capitalize;
      color: #212121;
      margin-bottom: 16px;
      padding-left: 4px;
    }
    .col-xl-5.d-flex.align-items-end.justify-content-end {
      display: none !important;
    }
    .about-team p {
      padding-right: 29px;
      padding-left: 5px;
    }

    .member-tile {
      margin-top: 27px;
      padding: 0px 9px;
    }

    .about-team ~ .home-customer {
      padding-bottom: 24px;
    }
}

.aboutimg{
  width: 100%;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.24);
}

.text-justify{
  text-align: justify;
}

.center{
  text-align: center;
}

@media only screen and (min-width: 800px) {
  .flex {
    display: flex;
    width: 80%;
    margin: auto;
    margin-top: -50px;
  }
}

@media only screen and (max-width: 800px) {
  .margin-right2{
    margin-right: 5%;
  }
  .margin-left2{
    margin-left: 5%;
  }
}

.margin-right{
  margin-right: 5%;
}
.margin-left{
  margin-left: 5%;
}