

.tab-pane::-webkit-scrollbar {
  width: 0.2em;
}
 
.tab-pane::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.tab-pane::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}



.demo .main-banner {
 text-align: center;
 padding-top: 106px;
 padding-bottom: 106px;

}

.demo .container-lg{
    position: relative;
	overflow:hidden;
}

.demo ul{
    list-style: none;
    padding: 0;
    /* margin-bottom: 82px; */
}

.demo ul.toottips li{
    width: 300px;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    padding: 32px 24px;
    position: absolute;
}

.demo  ul.toottips li:first-child {
    top: 139px;
    left: 10px;
}
.demo  ul.toottips li:nth-child(2) {
    bottom: 223px;
left: 20px;
}
.demo  ul.toottips li:nth-child(3) {
    top: 139px;
right: 21px;
}
.demo  ul.toottips li:last-child {
  bottom: 192px;

right: 21px;
}
  
ul.menu-list {
  margin-bottom: 10px;
  border: 0;
}

ul.menu-list li{
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #3D424D;

  margin-right: 0;


}
ul.menu-list li button{
  padding: 5px 7px !important;
  color: #3D424D;
}
  
ul.menu-list li button.nav-link.active{

  color: #05235F;
  border: 0;
  border-bottom: 1px solid;
}


ul.sub-menu-list{
  margin-bottom: 10px !important;
}
ul.sub-menu-list li{

  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  margin-right: 0;

}

ul.sub-menu-list li button{
  padding: 5px 7px !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
  color: #000000;

}

ul.sub-menu-list li button.nav-link.active{
  color: #05235F;
  border: 0;
  border-bottom: 1px solid;
}

.menu-tab-content{
  background: #F7F7F7;
  padding: 10px;
}
  

.demo ul li h6{
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    color: #000000;
    margin-bottom: 15px;
}

.demo ul li p{
    font-weight: 400;
    font-size: 14px;
    line-height: 152%;
    color: #3D424D;
    margin: 0;
}


.demo .main-banner span {
    position: absolute;
    z-index: 2;
  }

  .demo .main-banner .arr1 {
    top: 154px;
    left: 307px;
  }

  .demo .main-banner .arr2 {
    left: 318px;
    bottom: 301px;
  }

  .demo .main-banner .arr3 {
    top: 166px;

    right: 319px;
  }

  .demo .main-banner .arr4 {
    bottom: 149px;

  right: 317px;
}



.custom-menu-item {
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;

}

.menu-item-name{
  font-weight: 500;
  font-size: 16px;
  line-height: 122%;
  color: #171B26;
  text-align: left;
}
.menu-item-description{
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #3D424D;
    text-align: left;
}

.menu-item-price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.menu-item-price p{
  margin-bottom: 0;
}

.menu-item-price span {
 position: unset !important;
}

  

.main-banner > img{
    width: 39%;
}

.demo .mobile-container
{
  width: 100%;
  max-width: 450px;
  max-height: 900px;
  min-height: 800px;
  margin: 0 auto;
  border: 8px solid #FFFFFF;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  /* padding: 22px 0; */
  position: relative;


}

.demo .mobile-container iframe {
  padding-top: 3px;
  border: 1px solid #EBEBEB;
  border-radius: 24px;
}

.demo .mobile-container iframe {
  overflow: hidden;
  height: 800px;
}

.demo .mobile-container iframe::-webkit-scrollbar {  
  display: none;
} 

.cart--main-button span{
  position: unset !important;
}

.cart--main-button{
  width: 197px;
  margin: 20px  auto 0;
  background: #FEC168;
  border-radius: 8px;
  border: 1px solid transparent;
}


.cart--main-button p{
  margin-bottom: 0;
  font-weight: 500;
font-size: 14px;
line-height: 125%;
/* identical to box height, or 18px */


color: #000000;

}


.totalAmount{
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: #000000;
}

.menu-tab-content > .tab-pane{
  min-height: 400px;
  max-height: 470px;
  overflow-y: auto;
  overflow-x: hidden;
}

.menu-tab-content{
  min-height: 400px;
  max-height: 470px;
}

.demo .logo {
  margin-right: 55px;
  margin-bottom: 33px
}

.small-cart-icon{
  width: 20px;
}

.cartSection{
  background-color: #f5f5f5;
  color: #000;
  transition: all 1s;
  z-index: 100;
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.08);
  padding: 10px 15px;
  left: 2px;
}

.cartSection button{
  border: 0;
  background-color: transparent;
 
}

.cartSection li p{
  font-size: 16px !important; 
}
.cartSection li button{
  border: 1px solid #FEC168;
  background-color: white;
  border-radius: 100%;
  margin: 0 10px;
  width: 27px;

}

.cartSection li button:last-child{
  margin-right: 0;
}

.cartamount{
  background-color: white;
  padding: 0 8px;
  border-radius: 100%;
}


.mobile-container .modal{
  position: absolute !important;
  bottom: 0 !important;
}

.mobile-container .modal.show .modal-dialog {
  transform: none;
  position: absolute;
  bottom: 31px;
  width: 95%;
  left: 6px;

}


div#exampleModal:before {
  content: '';
  width: 97.5%;
  height: 477px;
  background: black;
  position: absolute;
  left: 2px;
  bottom: 60px;
  z-index: 0;
  opacity: 0.5;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
  /* position: absolute; */
  /* width: 100%; */
  display: none;
}


.start-mobile-app {
  position: absolute;
  top: 0;
  width: 100%;
  height: 800px;

}
.start-mobile-app:before {
  content: '';
  width: 100%;
  height: 800px;
  background: black;
  position: absolute;
  left: 0;
  opacity: 0.5;
  z-index: 0;
  border-radius: 24px;
}
.start-mobile-app button {
  z-index: 999;
    position: relative;
    top: 46%;
    left: 5%;
    transform: translate(-64%);
  animation: myAnim 4s ease 0s infinite normal forwards;

}

@keyframes myAnim {
	0%,
	50%,
	100% {
		transform: scale(1.2);
	}

	25%,
	75% {
		transform: scale(1);
	}
}



@media only screen and (max-width: 1200px){
    .demo ul li {
        width: 167px;
    }
    .demo .main-banner {
        text-align: center;
        padding-top: 343px;
        padding-bottom: 300px;
    }

    .demo ul li p {
        font-size: 12px;
    }

    .demo ul li:nth-child(2) {
    bottom: 0;
    left: 20px;
    }

    .demo ul li:last-child {
        bottom: 0;
        right: 21px;
    }
    .demo ul li:first-child {
    top: 55px;
    left: 21px;
    }
    .demo ul li:nth-child(3) {
        top: 55px;
        right: 21px;
    }
    .main-banner > img {
    width: 100%;
    }

    .demo .main-banner .arr3 {
        top: 267px;
        right: 253px;
        transform: rotate(-72deg);
        width: 70px;
    }
    .demo .main-banner .arr3 {
      top: 294px;
      right: 180px;
      transform: rotate(-3deg);
      width: 179px;
  }
  .demo ul.toottips li:last-child {
    bottom: 104px;
    right: 77px;
}
  .demo .main-banner .arr1 {
    top: 280px;
    left: 176px;
    width: 288px;
    transform: rotate(33deg);
}
.demo .main-banner .arr4 {
  bottom: 211px;
  right: 336px;
  transform: rotate(57deg);
  width: 187px;
}
.demo ul.toottips li:nth-child(2) {
  bottom: 89px;
  left: 20px;
}
.demo .main-banner .arr2 {
  left: 201px;
  bottom: 267px;
  width: 178px;
  transform: rotate(-27deg);
}
      .faq h2 {
        font-size: 28px;
        line-height: 38px;
      }
      .faq p {
        font-size: 16px;
        line-height: 26px;
        max-width: 361px;
      }
      .faq .accordion {
        padding: 0 7px;
      }
      .faq {
        padding-top: 60px;
        padding-bottom: 60px;
        position: relative;
      }
      .faq .accordion-button {
        font-size: 16px;
      }


      .mobile-container .logo img{
        width: 154px !important;
      }
      .mobile-container .col-lg-2 img {
        width: 15px !important;
      }

      .mobile-container .menu-list img {
        width: 48px !important;

      }

      
      .demo  ul.menu-list li {
        width: 71px;
    }


    .demo  ul.sub-menu-list li {
      width: 90px;
      }

      .demo  ul.sub-menu-list li button{
        margin-right: 0 !important;
        width: 100%;

    }
}



@media only screen and (max-width: 900px){

  .demo .main-banner .arr1 {
    top: 307px;
    left: 115px;
    width: 184px;
    transform: rotate(48deg);
}

  .custom-menu-item .col-lg-8{
    width: 66.66666667% !important;
  }
  
  .custom-menu-item .col-lg-4{
    width: 33.33333333% !important;

  }
  .demo .main-banner .arr3 {
    top: 294px;
    right: 41px;
    transform: rotate(-3deg);
    width: 191px;
}
.demo .main-banner .arr4 {
  bottom: 211px;
  right: 130px;
  transform: rotate(57deg);
  width: 187px;
}

}


@media only screen and (max-width: 767px){

  .demo ul.menu-list li {
    width: 62px;
}
.demo ul.toottips li {
  width: 167px;
}
.demo ul.toottips li:first-child {
  top: 59px;
  left: 10px;
}
.demo ul.toottips li:nth-child(3) {
  top: 58px;
  right: 21px;
}

.demo .main-banner .arr1 {
  top: 302px;
  left: 40px;
  width: 139px;
  transform: rotate(52deg);
}

.demo .main-banner .arr3 {
  top: 302px;
  right: -44px;
  transform: rotate(-26deg);
  width: 170px;
}

.demo ul.toottips li:last-child {
  bottom: 3px;
  right: 0;
}
.demo .main-banner .arr4 {
  bottom: 224px;
  right: 39px;
  transform: rotate(77deg);
  width: 117px;
}
.demo ul.toottips li:nth-child(2) {
  bottom: 0;
  left: 20px;
}

.demo .main-banner .arr2 {
  left: -8px;
  bottom: 267px;
  width: 165px;
  transform: rotate(-47deg);
}

body{
  padding-right: 0 !important;
}

.mobile-container .modal.show .modal-dialog {
  transform: none;
  position: absolute;
  bottom: 31px;
  width: 95%;
  left: -2px;
}
}
