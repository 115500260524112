.pos {
    padding-top: 101px;
  }


  .pos .logo {
    margin: 0 auto 37px;
    width: auto;
    text-align: center;
  }

  .pos h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    margin-bottom: 40px;
  }

  .pos-tile {
    padding: 49px 10px 35px;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 8px 8px 55px -2px #F2F3F6;
    border-radius: 12px;
    margin-bottom: 30px;
  }
  .pos-tile p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 136%;
    color: #000000;
  }

  .pos-tile.lightspeed {
    padding: 59px 10px 10px;
  }

  .pos-tile.lightspeed .logo{
    margin: 0 auto 52px;
  }

  .pos.recipt {
    padding-bottom: 87px;
  }


  @media screen and (max-width: 1200px) {

      .pos {
        padding-top: 53px;
        padding-left: 8px;
        padding-right: 8px;
      }

      .pos-tile.lightspeed .logo {
        max-width: 63px;
        margin: 0 auto 35px;
      }
      .pos .logo {
        max-width: 120px;
        margin: 0 auto 21px;
      }
      .pos .col-xl-3 {
        width: 50%;
      }

      .pos-tile.lightspeed {
        padding: 32px 10px 10px;
      }

      .pos-tile {
        padding: 20px 10px 10px;
        margin-bottom: 10px;
      }

      .pos h2 {
        font-size: 24px;
         line-height: 30px;
        margin-bottom: 30px;

      }


      .pos.recipt {
        padding-bottom: 42px;
      }













}